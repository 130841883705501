@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");

body {
  margin: 0;
  padding: 0px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden; 
}

.d-flex-row-start-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.d-flex-row-start-center {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.d-flex-row-start-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}

.d-flex-row-center-start {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.d-flex-row-center-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.d-flex-row-center-end {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.d-flex-row-end-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
}

.d-flex-row-end-center {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.d-flex-row-end-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.d-flex-row-between-start {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.d-flex-row-between-center {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.d-flex-row-between-end {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.d-flex-row-around-start {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
}

.d-flex-row-around-center {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.d-flex-row-around-end {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
}

.d-flex-col-start-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.d-flex-col-start-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.d-flex-col-start-end {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.d-flex-col-center-start {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.d-flex-col-center-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.d-flex-col-center-end {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.d-flex-col-end-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.d-flex-col-end-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.d-flex-col-end-end {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.d-flex-col-between-start {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.d-flex-col-between-center {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.d-flex-col-between-end {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
