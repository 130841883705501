.chatbot-sidebar-search-container {
  .input-group-text {
    background-color: transparent !important;
  }
  
  .form-control {
    border-left: none;
  }
}

